<template>
  <div class="body_wrap">
   <div class="line_s"></div>
   <el-steps :active="active" finish-status="success" align-center>
      <el-step title="买家申请退款"></el-step>
      <el-step title="商家处理退款"></el-step>
      <el-step title="退款完毕"></el-step>
     </el-steps>
   <div class="main_return">
   <div class="return_main">
        <div class="return_main_s">
          <el-row class="return-table">
            <el-col :span="9">
               <div class="grid-content bg-purple">产品名称</div>
            </el-col>
            <el-col :span="5"><div class="grid-content bg-purple">单价</div></el-col>
            <el-col :span="5"><div class="grid-content bg-purple">数量</div></el-col>
            <el-col :span="5"><div class="grid-content bg-purple">小记</div></el-col>
          </el-row>
          <div class="return_main_s_con">
            <el-row>
                <el-col :span="9">
                    <el-row>
                      <el-col :span="8">
                        <img src="../../assets/image/chanpintu.png" alt>
                      </el-col>
                      <el-col :span="16">
                        <p class="product-name"></p>
                        <p>属性：属性值此处为产品名称此处为产品名称此处为产品名称</p>
                        <p>属性：属性值</p>
                        <p>属性：属性值</p>
                      </el-col>
                    </el-row>
                  </el-col>
                <el-col :span="5">
                    <div class="product-price">￥200</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="product-num">1</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="product-total-price">￥200</div>
                  </el-col>
              </el-row>
          </div>
          <!-- 选择服务 -->
          <!-- <div class="return_main_s_select">
            <p class="return_main_s_select_tip">选择服务类型</p>
            <el-row class="return_main_s_select_con">
               <el-col :span="11" class="grid-content left">
                  <el-row>
                    <el-col :span="3">
                      <img src="../../assets/image/tuikuan.png" alt>
                    </el-col>
                    <el-col :span="19">
                      <p class="left_20">仅退款</p>
                      <p class="left_14">未收到货（包含未签收），或与商家协商同意前提下</p>
                    </el-col>
                  </el-row>
                </el-col>
               <el-col :span="11" class="grid-content center">
                </el-col>
               <el-col :span="11" class="grid-content right">
                  <el-row>
                    <el-col :span="3">
                      <img src="../../assets/image/tuihuotuikuan.png" alt>
                    </el-col>
                    <el-col :span="19" >
                      <p class="left_20">退货退款</p>
                      <p class="left_14">未收到货（包含未签收），或与商家协商同意前提下</p>
                    </el-col>
                  </el-row>
                </el-col>
            </el-row>
          </div> -->
          <!-- 申请退货退款 -->
          <!-- <div class="return_main_s_apply">
            <p class="return_main_s_apply_tip">等待商家处理</p>
            <p class="color1">您已成功发起退款申请，请耐心等待商家处理。</p>
            <p class="color2">如果商家拒绝退款申请，您可以申请平台介入</p>
             <p class="btn">撤消申请</p>
             <div class="process">
                 <p class="process_tip">协商进度</p>
                    <div class="process_info">
                     <el-row>
                      <el-col :span="2">
                        <img src="../../assets/image/chanpintu.png" alt>
                      </el-col>
                      <el-col :span="21">
                        <p>王小二<span>&nbsp;&nbsp;2019-12-12 12:22</span></p>
                        <p>发起了仅退款申请，货物状态：已收到货，原因：退款原因/退款原因，金额：200.00元</p>
                      </el-col>
                    </el-row>
                    </div>
             </div>
          </div> -->
          <!-- 申请退款 -->
          <!-- <div class="return_main_s_money">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="服务类型：" prop="resource" required>
                  <el-radio-group v-model="ruleForm.resource">
                    <el-radio label="1">仅退款</el-radio>
                    <el-radio label="2">退货退款</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="货物状态：" prop="resource" required>
                  <el-radio-group v-model="ruleForm.resource">
                    <el-radio label="1">已收到货</el-radio>
                    <el-radio label="2">未收到货</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="退款金额：" prop="name" required>
                  <el-input class="input" v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="退款原因：" prop="region" required>
                  <el-select class="input" v-model="ruleForm.region" placeholder="请选择退款原因">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="退款说明：" prop="desc" required>
                  <el-input class="input1" type="textarea" placeholder="请输入退款具体说明" v-model="ruleForm.desc"></el-input>
                </el-form-item>
                <el-form-item label="上传凭证：" prop="desc">
                 <el-upload
                    action="https://jsonplaceholder.typicode.com/posts/"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible" size="tiny">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                </el-form-item>
                <el-form-item>
                  <el-button class="btn_sub" type="primary" @click="submitForm('ruleForm')">提交</el-button>
                </el-form-item>
              </el-form>
          </div> -->
          <!-- 申请退货退款-拒绝退货申请. -->
           <!-- <div class="return_main_s_apply">
            <p class="return_main_s_apply_tip">商家拒绝了退款申请</p>
            <p class="color1">拒绝原因：此处是拒绝原因</p>
            <p class="color2">拒绝说明：无</p>
             <p class="btn">申请平台介入</p>
             <div class="process">
                 <p class="process_tip">协商进度</p>
                    <div class="process_info">
                     <el-row class="row">
                        <el-col :span="2" >
                          <img src="../../assets/image/chanpintu.png" alt>
                        </el-col>
                        <el-col :span="21">
                          <p>王小二<span>&nbsp;&nbsp;2019-12-12 12:22</span></p>
                          <p>发起了仅退款申请，货物状态：已收到货，原因：退款原因/退款原因，金额：200.00元</p>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="2">
                          <img src="../../assets/image/chanpintu.png" alt>
                        </el-col>
                        <el-col :span="21">
                          <p>王小二<span>&nbsp;&nbsp;2019-12-12 12:22</span></p>
                          <p>发起了仅退款申请，货物状态：已收到货，原因：退款原因/退款原因，金额：200.00元</p>
                        </el-col>
                      </el-row>
                    </div>
             </div>
           </div> -->
           <!-- 申请退货退款-买家退货 -->
           <div class="return_main_s_apply">
           <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="退款方式：" prop="region" required>
                  <el-select class="input" v-model="ruleForm.region" placeholder="请选择退款原因">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="退款说明：" prop="desc" required>
                  <el-input class="input1" type="textarea" placeholder="请输入退款具体说明" v-model="ruleForm.desc"></el-input>
                </el-form-item>
                <el-form-item label="上传凭证：" prop="desc">
                 <el-upload
                    action="https://jsonplaceholder.typicode.com/posts/"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible" size="tiny">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                </el-form-item>
                <el-form-item>
                  <el-button class="btn_sub" type="primary" @click="submitForm('ruleForm')">提交</el-button>
                  <el-button class="btn_sub" type="primary" @click="submitForm('ruleForm')">取消</el-button>
                </el-form-item>
              </el-form>
             <div class="process">
                 <p class="process_tip">协商进度</p>
                    <div class="process_info">
                     <el-row class="row">
                        <el-col :span="2" >
                          <img src="../../assets/image/chanpintu.png" alt>
                        </el-col>
                        <el-col :span="21">
                          <p>王小二<span>&nbsp;&nbsp;2019-12-12 12:22</span></p>
                          <p>发起了仅退款申请，货物状态：已收到货，原因：退款原因/退款原因，金额：200.00元</p>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="2">
                          <img src="../../assets/image/chanpintu.png" alt>
                        </el-col>
                        <el-col :span="21">
                          <p>王小二<span>&nbsp;&nbsp;2019-12-12 12:22</span></p>
                          <p>发起了仅退款申请，货物状态：已收到货，原因：退款原因/退款原因，金额：200.00元</p>
                        </el-col>
                      </el-row>
                    </div>
             </div>
           </div>
        </div>
  </div>
  <div class="info">
    <BusinessInfo></BusinessInfo>
  </div>
   </div>
  </div>
</template>

<script>
import BusinessInfo from "@/components/common/businessInfo";
export default {
  name: 'index',
   components: { BusinessInfo },
  data() {
    return {
      active: 1,
      ruleForm: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        dialogVisible: false,
        dialogImageUrl: "",
        rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
          date1: [
            { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
          ],
          date2: [
            { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
          ],
          type: [
            { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
          ],
          resource: [
            { required: true, message: '请选择活动资源', trigger: 'change' }
          ],
          desc: [
            { required: true, message: '请填写活动形式', trigger: 'blur' }
          ]
        }
    }
  },
  methods: {
    handlePictureCardPreview(){},
    handleRemove(){}
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/common.scss";
@mixin icon($m) {
  $url: "../../assets/image/" + $m;
  @include bgImage($url);
}
  .line_s{
     width: 100%;
      height: 40px;
  }
.main_return{
  display: flex;
  margin-top:50px;
.return_main {
  overflow: hidden;
  clear: both;
  background:#fff;
  &_s{
    min-width: 1000px;
    // text-align: center;
    // margin: 20px;
  // padding: 0 21px 39px 21px;
    border:1px solid #eee;
    .return-table{
      background-color: #f5f5f5;
      padding: 10px;
      font-size:15px;
      color: #666;
    }
     &_con{
        padding: 20px;
        border-bottom:1px solid #eee;
          img {
            width: 80px;
            height: 80px;
          }
          p {
            width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 15px;
            color: #333;
            text-align: left;
          }
          .product-price{
            font-size: 15px;
            color: #333;
          }
          .product-num{
             font-size: 15px;
            color: #333;
          }
          .product-total-price{
              font-size: 15px;
            color: #ff0036;
          }
      }
      &_select{
        &_tip{
          text-align: left;
          padding: 10px;
          font-size: 15px;
          color: #333;
        }
        &_con{
          text-align: left;
          margin: 20px;
          height: 148px;
          img{
            width: 40px;
            height: 40px;
          }
          .center{
            width: 19px;
            padding-top: 20px;
            background: #fff;
            margin-top: 10px;
            height: 100%;
          }
          .left{
            background-color: #e6f7f0;
            padding-top: 50px;
            padding-left: 20px;
            height: 100%;
            margin-left: 4%;
             cursor: pointer;
            .left_20{
              font-size: 20px;
              color: #333;
            }
            .left_14{
              font-size: 14px;
              color: #666;
            }
          }
          .right{
             height: 100%;
             background-color: #f8eddd;
             padding-left: 20px;
             padding-top: 50px;
             cursor: pointer;
            .left_20{
              font-size: 20px;
              color: #333;
            }
            .left_14{
              font-size: 14px;
              color: #666;
            }
          }
        }
      }
      &_apply{
        padding-top: 20px;
        &_tip{
          text-align: left;
          padding: 10px;
          font-size: 16px;
          color: #0db168;
           padding-left: 30px;
        }
        p{
          line-height: 25px;
        }
        .btn{
          width: 110px;
          height: 34px;
          line-height: 34px;
          background-color: #0db168;
          border-radius: 2px;
          text-align: center;
          color: #fff;
          margin-bottom: 27px;
          margin-left: 30px;
        }
         .color1{
           font-size: 16px;
           color: #333;
          text-align: left;
          padding-left: 30px;
        }
         .color2{
          text-align: left;
           font-size: 16px;
           color: #999;
           padding-left: 30px;
        }
        .process{
          width: 100%;
          border-top: 1px solid #e5e5e5;
          padding: 22px 0 31px 30px;
          box-sizing: border-box;
          &_tip{
            font-size: 16px;
            color: #333;
            text-align: left;
          }
          &_info{
            margin-top: 24px;
            .row{
              margin: 5px auto;
            }
            img{
              width: 60px;
              height: 60px;
              background-color: #0db168;
              border-radius: 50%;
            }
            p{
              font-size:15px;
              color: #333;
              text-align: left;
              line-height: 30px;
            }
            span{
              color: #999;
            }
          }
        }
      }
      &_money{
        // width: 100%;
        // min-height: 747px;
        padding-top:45px;
        .input{
          width:336px;
          padding: 0;
        }
        .input1{
           width:475px;
          padding: 0;
        }
      }
      .input{
          width:336px;
          padding: 0;
        }
        .input1{
           width:475px;
          padding: 0;
        }
        .btn_sub{
          width: 110px;
          height: 40px;
          background-color: #0db168;
          border-radius: 2px;
          color: #fff;
          border: 0;
        }
  }
}
  .info{
    margin: 20px;
  }
}
</style>

